import { createFeatureSelector, createSelector } from '@ngrx/store';

import { coreStateKey, State } from '@core/store/reducers/core.reducer';

export const selectCoreState = createFeatureSelector<State>(coreStateKey);

export const selectSubscriptionsState = createSelector(
  selectCoreState,
  coreState => coreState.subscriptions
);

export const selectSubscriptions = createSelector(selectSubscriptionsState, state => state.items);

export const selectLoading = createSelector(selectSubscriptionsState, state => state.loading);

export const selectLoaded = createSelector(selectSubscriptionsState, state => state.loaded);
